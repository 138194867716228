<template>
  <div>
    <v-switch
      class="ml-2"
      :disabled="disabled"
      v-model="computedValue"
      :label="computedValue ? $t('t.Yes'): $t('t.No')"
      ref="input"
      :readonly="readonly"
    />
  </div>
</template>

<script>
export default {
  components: {
  },
  computed: {
    computedValue: {
      get () { return this.dataValues?.[0] === true },
      set (v) {
        this.dataValues = [v === true]
        this.dataDocumentEmit()
      }
    }
  },
  data () {
    return {
      required: [v => (!this.lodash.isNil(v) && v !== '') || this.$t('t.IsRequired')],
      dataValues: [],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  inheritAttrs: false,
  methods: {
    emitDocument () {
      if (!this.lodash.isEqual(this.document, this.dataValues)) {
        this.$emit('update:document', this.lodash.cloneDeep(this.dataValues))
      }
    },
    removeValue (idx) {
      this.dataValues.splice(idx, 1)

      if (this.dataValues.length === 0) {
        this.dataValues = [true]
      }
    }
  },
  props: {
    document: Array,
    disabled: Boolean,
    dense: Boolean,
    readonly: Boolean
  },
  watch: {
    document: {
      handler (v) {
        this.dataValues = v?.length ? this.lodash.cloneDeep(v) : [true]
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
